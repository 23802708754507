<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>私教订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="标题">
                <el-input v-model="search.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="searchtime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-select
                  v-model="search.state"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="取消" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--                        <el-col :span="6">
                                                    <el-form-item label="支付状态">
                                                        <el-select v-model="search.pay_state" placeholder="请选择状态" style="width: 100%">
                                                            <el-option label="全部" value=""></el-option>
                                                            <el-option label="已支付" :value=1></el-option>
                                                            <el-option label="未支付" :value=0></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>-->
            <el-col :span="6">
              <el-form-item label="课程名称">
                <el-input v-model="search.lesson_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="教练昵称">
                <el-input v-model="search.coach_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="教练手机号">
                <el-input v-model="search.coach_phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button
                  icon="el-icon-circle-close"
                  size="medium"
                  @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
            <el-button
              icon="el-icon-download"
              size="medium"
              type="primary"
              @click="exportOrder"
              >导出
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="title" label="标题" width="200">
        </el-table-column>
        <el-table-column prop="lesson_name" label="课程名称" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="教练昵称" width="200">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="22"
              :src="scope.row.portrait"
            ></el-avatar>
            <span
              >{{ scope.row.coach_nick }}（{{ scope.row.coach_name }}:{{
                scope.row.coach_phone
              }}）</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="用户昵称" width="200">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="22"
              :src="scope.row.avatar_url"
            ></el-avatar>
            <span>{{ scope.row.user_nick }}（{{ scope.row.phone }}）</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="200">
        </el-table-column>
        <el-table-column prop="lesson_type" label="课程类型" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.lesson_type === 1">团课</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.lesson_type === 2"
              type="success"
              >训练营</el-tag
            >
            <el-tag size="mini" v-if="scope.row.lesson_type === 3" type="danger"
              >私教</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="1 v X" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.qty }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="总价" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.total_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="优惠金额" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.coupon_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="支付金额" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.pay_money }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pay_type"
          label="支付方式/支付状态/支付单号"
          width="300"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.pay_type === 'BALANCE'">余额支付</span>
            <span v-if="scope.row.pay_type === 'WXPAY'">微信支付</span>
            <span v-if="scope.row.pay_type === 'ALIPAY'">支付宝</span>
            <span v-if="scope.row.pay_type === 'CARD'">商家卡</span>
            <span v-if="scope.row.pay_type === 'SCORE'">积分支付</span>
            <span v-if="scope.row.pay_type === 'CONSUMER_CARD'">消费卡</span>
            /
            <el-tag size="mini" v-if="scope.row.pay_state === 1">已支付</el-tag>
            <el-tag size="mini" v-if="scope.row.pay_state === 0" type="warning"
              >未支付</el-tag
            >
            /{{ scope.row.pay_order_no }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="支付时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_time !== '1970-01-01 08:00:00'">{{
              scope.row.pay_time
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态" width="80">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
            <el-tag size="mini" v-if="scope.row.state === 2" type="warning"
              >排队中</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 5" type="danger"
              >取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="is_queue_up"
          label="退款状态/退款金额/退款单号"
          width="300"
        >
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-if="scope.row.refund_state === 1"
              type="warning"
              >有退款</el-tag
            >
            <span v-if="scope.row.refund_state === 1">
              /￥{{ scope.row.refund_money }} /{{ scope.row.refund_order_no }}
            </span>
            <el-tag size="mini" v-if="scope.row.refund_state === 0">无</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_queue_up"
          label="退款时间/退款备注"
          width="300"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.refund_time !== '1970-01-01 08:00:00'"
              >{{ scope.row.refund_time }}/</span
            >
            <span>{{ scope.row.refund_remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180">
        </el-table-column>
        <!--                <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="330">
                                    <template slot-scope="scope">
                                        <el-button
                                                v-if="is_auth('')"
                                                @click="tosee(scope.row.good_uuid)"
                                                size="mini">查看
                                        </el-button>

                                    </template>
                                </el-table-column>-->
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      page_name: "私教订单",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      searchtime: null, // 时间条件
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {};
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "order.pt.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
        }
      });
    },
    // 导出订单列表
    exportOrder() {
      let postdata = {
        api_name: "order.pt.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes",
      };
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if(res.code === 10005){
              this.Tool.errormes(res);
          }else{
              location.href = res;
          }
          this.loading = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
